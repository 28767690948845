<i18n>
{
    "ru": {
        "recent": "Недавно просмотренные"
    }
}
</i18n>

<template>
    <div class="app__delayed-block" 
        v-observe-visibility="vsibilityObserverOptions">
        <PageBlock
            v-if="items.length > 0"
            :content-visible="isDeviceDesktop"
            :title="$t('recent')"
            class="page-block_recent"
            :no-margin="isDeviceDesktop">

            <BlockPersonsRow
                class="block-persons-row_recent"
                :swiper="isDeviceDesktop"
                :slides-per-view="perView"
                :space-between="19"
                :loop="false"
                :items="items">
                <template #item="{ item }" >
                    <BlockOfferPictureOnly :type="code" :offer-data="item[code]" />
                </template>
            </BlockPersonsRow>

        </PageBlock>
    </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import { ObserveVisibility } from 'vue-observe-visibility';

import PageBlock from '@/components/layout/AppPageBlock';
import BlockPersonsRow from '@/components/catalog/persons/BlockPersonsRow';
import BlockOfferPictureOnly from '@/components/catalog/BlockOfferPictureOnly';


export default {
    name: 'RecentItems',
    components: {
        PageBlock,
        BlockPersonsRow,
        BlockOfferPictureOnly
    },
    directives: {
        'observe-visibility': ObserveVisibility
    },
    props: {
        code: {
            type: String,
            default: 'perfume'
        },
        perView: {
            type: Number,
            default: 8
        }
    },
    data(){
        return {
            items: [],
            vsibilityObserverOptions: {
                callback: this.init,
                throttle: 0,
                once: true,
                throttleOptions: {
                    leading: 'visible',
                },
                intersection: {
                    threshold: 0,
                }
            }
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop'])
    },
    methods: {
        async init(isVisible){
            if(isVisible){
                await this.$api.get('/users/view-history', {params: {
                    page: 1,
                    per_page: 30,
                    entity_code: this.code
                }}).then(result => {
                    this.items = get(result, 'data.items', []);
                    // this.items = [];
                }).catch(error => {
                    console.log(error.message);
                });
            }
        }
    }
}
</script>

<style lang="less" scoped>
.app__delayed-block{
    min-height: rem(1);
}
</style>
<template>
    <div class="article-item-row">
        <div class="article-item-row__block-picture">
            <img v-lazy-load :data-src="computedPicture"
                class="article-item-row__picture"
                :alt="computedAlt" />
        </div>
        <div class="article-item-row__content" :class="contentClasses">
            <!-- eslint-disable-next-line -->
            <div class="article-item-row__title" v-html="data.name"></div>
            <html-link
                class="article-item-row__link"
                :url="data.htmlUrl"
                :aria-label="data.name" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ArticleRowItem',
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        verticalCenter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['getNoImage']),
        computedPicture() {
            return (this.data.picture && this.data.picture.url)
                ? this.data.picture.url
                : this.getNoImage
        },
        computedAlt() {
            return this.data.picture ? this.data.picture.alt : ''
        },
        contentClasses() {
            return {
                'article-item-row__content_vertical-center': this.verticalCenter
            }
        }
    }
};
</script>

<style lang="less">
.article-item-row {
    display: flex;
    align-items: center;

    padding-top: rem(17);
    padding-bottom: rem(17);

    height: rem(80);
    width: 100%;

    position: relative;

    .page-padding();

    .hover({
        color: @color-primary;
    });

    @{desktop} & {
        padding-top: rem(16);
        padding-bottom: rem(16);
    }

    &__block-picture {
        margin-right: rem(20);

        @{desktop} & {
            margin-right: rem(16);
        }
    }

    &__picture {
        object-fit: cover;

        border-radius: rem(3);

        height: rem(48);
        width: rem(48);
    }

    &__content {
        align-self: flex-start;

        &_vertical-center {
            align-self: center;
        }
    }

    &__title {
        display: -webkit-box;

        font-size: rem(15);
        font-weight: 500;
        line-height: 1.5;
        text-overflow: ellipsis;

        margin-bottom: 0;
        padding-right: rem(7);

        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @{desktop} & {
            font-size: rem(14);
        }
    }

    &__link {
        position: absolute;
        top: rem(5);
        right: rem(5);
        bottom: rem(5);
        left: rem(5);
    }

    &:not(:last-child) {
        border-bottom: rem(1) solid @color-gray-white;
    }
}
</style>

<template>
    <div class="linked-pages-list">
        <html-link
            class="linked-pages-list__link"
            :url="page.htmlUrl"
            v-for="(page, index) in items"
            :key="index + page.code">
            {{ page.name }}
        </html-link>
    </div>
</template>

<script>
export default {
    name: 'LinkedPagesList',
    props: {
        items: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="less">
.linked-pages-list {
    display: flex;
    flex-wrap: wrap;

    padding-top: rem(5);
    padding-bottom: rem(24);

    width: 100%;

    position: relative;
    .page-padding();

    @{desktop} & {
        padding-top: 0;
    }

    &__link {
        color: @color-gray;
        line-height: 1.5;

        padding-top: rem(9);

        width: 100%;

        @{desktop} & {
            padding-top: rem(9);
        }

        .hover({
            color: @color-primary;
        });
    }
}
</style>

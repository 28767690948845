import { mapGetters } from 'vuex';
import get from 'lodash/get';
import url from '@/filters/url';

export default {
    data(){
        return {
            prevPage: null,
            nextPage: null
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        totalPhrase() {
            let text = this.$options.filters.numberDeclension(
                this.total, 
                this.declensions, 
                'catalog-page__total-val'
            );

            if (this.q || Object.keys(this.filterParams).length > 0) {
                text += ' ' + this.$t('by_filter');
            }

            return text;
        },
        totalPlainPhrase() {
            let text = this.$options.filters.numberDeclension(
                this.total, 
                this.declensions
            );

            if (this.q || Object.keys(this.filterParams).length > 0) {
                text += ' ' + this.$t('by_filter');
            }

            return text;
        },
        pageHeadCounter(){
            const pageLocale = this.$i18n.locale === 'ru' ? 'Страница' : 'Page'
            return this.page > 1 ? ` - ${pageLocale} ${this.page}`  : ''
        }
    },
    watch: {
        page(value) {
            this.modify({page: value});
        },
        q(value) {
            if (!value || value.length < 1 || value.length > 1) {
                if (!value || decodeURI(value) === value) {
                    this.modify({q: value ? decodeURI(value) : null, page: null});
                }
            }
        },
        letter(value) {
            this.modify({letter: value ? decodeURI(value) : null, page: null});
        }
    },
    mounted() {
        this.filtersCount = this.$refs.filter.filtersCount;

        // детектим навигацию браузера назад\вперед
        window.addEventListener('popstate', this.onPopState);
    },
    beforeDestroy(){
        window.removeEventListener('popstate', this.onPopState);
    },
    methods: {
        onPopState(){
            window.location.reload();
        },
        onChangeSort(value) {
            this.sort = value;
            this.modify({sort: value, page: null});
        },
        onChangeOrder(value) {
            this.order = value
            this.modify({order: value, page: null});
        },
        onFilterSubmit($event) {
            this.filterIsShowed = false;
            this.updateByUrl(this.applyIfSetted($event.url, 'q', 'sort', 'order', 'letter'));
        },
        onFilterReset() {
            const url = get(this.$route, 'matched[0].path', this.$route.path);
            this.updateByUrl(this.applyIfSetted(url, 'q', 'sort', 'order'));
        },
        resetAll() {
            const url = get(this.$route, 'matched[0].path', this.$route.path);
            this.updateByUrl(this.applyIfSetted(url, 'sort', 'order'));
        },
        /**
         * Добавляет сортировку, поиск
         * 
         * @param  {String} link
         * @param  {Object} keys
         * @return {String}
         */
        applyIfSetted(link, ...keys) {

            const defaultValues = {
                q: null,
                letter: null,
                sort: Object.keys(this.sortOptions)[0],
                order: 'desc'
            };

            const values = {};

            for (const i in keys) {
                if (this[keys[i]] && this[keys[i]] !== defaultValues[keys[i]]) {
                    values[keys[i]] = this[keys[i]];
                }
            };

            return url.modify(link, values);
        },
        /**
         * Модифицирует параметры и обновляет каталог
         * 
         * @param  {Object} params
         * @return 
         */
        modify(params) {
            if (params.q || params.sort || params.page) {
                this.$refs.catalog.setLoading();
            };
            this.updateByUrl(
                url.modify(window.location.href, params)
            );
        }, 
        /**
         * Обновляет URL и каталог по нему
         * 
         * @param  {String} url
         * @return
         */
        updateByUrl(url) {
            window.history.pushState({refreshed: this.$route.name, url}, null, url);
            this.$nuxt.$root.$loading.start();

            this.$nuxt.refresh().then(() => {
                this.$refs.catalog.unsetLoading();
            });
        },
        metaNextPage(){
            let url = this.$i18n.__baseUrl

            if (this.page === 1){
                url += this.$route.path + 'page/' + this.nextPage + '/'
            }else{
                url += this.$route.path.replace(/\/page\/[0-9]+/, '/page/' + this.nextPage)
            }

            return url
        },
        metaPrevPage(){
            let url = this.$i18n.__baseUrl

            if(this.page === 2){
                url += this.$route.path.replace(/\/page\/[0-9]+/, '')
            }else{
                url += this.$route.path.replace(/\/page\/[0-9]+/, '/page/' + this.prevPage)
            }

            return url
        },
        metaCanonical(){
            return this.$i18n.__baseUrl + this.$route.path.replace(/\/page\/[0-9]+/, '')
        }
    },
    head() {
        const meta = {
            title: get(this.meta, 'title', this.metaDefault.title) + this.pageHeadCounter,
            meta: this.$options.filters.metaApiExtract({meta: this.meta}, this.metaDefault),
            link: []
        };

        if (this.page > 1) {
            meta.link.push({
                hid: 'i18n-can',
                rel: 'canonical',
                href: get(this.meta, 'canonical', this.metaCanonical())
            });
        }

        if (this.page > 1 || this.letter)
            meta.meta.push({
                hid: 'robots',
                name: 'robots',
                content: 'noindex, follow'
            })

        if(!this.filtersCount){

            if(this.nextPage)
                meta.link.push(
                    {
                        rel: 'next',
                        href: this.metaNextPage()
                    }
                )

            if(this.prevPage)
                meta.link.push(
                    {
                        rel: 'prev',
                        href: this.metaPrevPage()
                    }
                )
        }

        return meta
    }
}